import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SharmalLogo from '../../assets/images/SharmalLogo.png';
import UserExchangeRateCardComponent from '../Card/UserExchangeRateCardComponent';
import theme from '../../theme';

function UserNavbarComponent({ history }) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen((prevOpen) => !prevOpen);
  };

  const renderMenuItems = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"


    >
      <List>
        <ListItem button onClick={() => history.push('/home')}>
          <ListItemText primary="ပင်မစာမျက်နှာ" />
        </ListItem>

        {/* Sub-menu trigger for 'အိမ်ခြံမြေ' */}
        <ListItem button onClick={handleSubMenuToggle}>
          <ListItemText primary="အိမ်ခြံမြေ" />
          {isSubMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        {/* Sub-menu items */}
        <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button sx={{ pl: 4 }} onClick={() => history.push('/property?State=ရောင်းရန်')}>
              <ListItemText primary="အိမ်ခြံမြေ အရောင်း" />
            </ListItem>
            <ListItem button sx={{ pl: 4 }} onClick={() => history.push(`/property?State=ငှားရန်`)}>
              <ListItemText primary="အိမ်ခြံမြေ အငှား" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button>
          <ListItemText primary="ကား" onClick={() => history.push('/car')} />
        </ListItem>
        <ListItem button onClick={() => history.push('/contact')}>
          <ListItemText primary="ဆက်သွယ်ရန်" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ display: 'flex', flexDirection: 'row', paddingX: '20px', backgroundColor: theme.homePage.navbar }}>
        <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <img
            src={SharmalLogo}
            alt="logo"
            style={{ width: 'auto', height: '40px', cursor: 'pointer' }}
            onClick={() => history.push('/home')}
          />

          {isMobile ? (
            <>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon sx={{ color: '#000000' }} />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                {renderMenuItems()}
              </Drawer>
            </>
          ) : (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: '50px'
            }}>
              <Typography onClick={() => history.push('/home')} variant='p' sx={{ cursor: 'pointer', color: "#000000" }}>ပင်မစာမျက်နှာ</Typography>

              {/* Updated click handler to open the drawer in mobile mode */}
              <Typography
                variant='p'
                onClick={isMobile ? toggleDrawer(true) : handleMenuOpen}
                sx={{ display: "flex", alignItems: "center", cursor: 'pointer', color: "#000000" }}
              >
                အိမ်ခြံမြေ <KeyboardArrowDownIcon />
              </Typography>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => {
                  history.push(`/property?State=ရောင်းရန်`);
                }}>အိမ်ခြံမြေ အရောင်း</MenuItem>
                <MenuItem onClick={() => {
                  history.push(`/property?State=ငှားရန်`);
                }}>
                  အိမ်ခြံမြေ အငှား
                </MenuItem>
              </Menu>

              <Typography variant='p' onClick={() => history.push('/car')} sx={{ cursor: 'pointer', color: "#000000" }}>ကား</Typography>
              <Typography onClick={() => history.push('/contact')} variant='p' sx={{ cursor: 'pointer', color: "#000000" }}>ဆက်သွယ်ရန်</Typography>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Exchange rate card component */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto',
          gap: { xs: '20px', sm: '60px' },
          bgcolor: 'background.paper',
          borderRadius: 1,
          padding: '10px',
          textAlign: 'center'
        }}
      >
        <Typography
          variant='p'
          className='gradient-text'
          sx={{
            fontSize: { xs: '14px', sm: '16px' },
            fontWeight: 'bold',
          }}
        >
          ယနေ့ငွေလဲဈေးနှုန်းများ
        </Typography>

        <UserExchangeRateCardComponent />
      </Box>
    </Box>
  );
}

export default UserNavbarComponent;
